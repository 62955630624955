import Cookie from 'js-cookie'
import {
  CART_CREATE,
  CART_LINES_ADD,
  GET_CART_CHECKOUT_URL,
  GET_CART_ID_AND_CHECKOUT_URL,
  CART_DISCOUNT_CODES_UPDATE,
  GET_CART_LINES,
  CART_LINES_REMOVE
} from '@/shopify/checkout'
import { findCollectionProductsByHandle } from '@/shopify/products'
export default class ShopifyApolloService {
  constructor(apolloProvider) {
    this.client = null
    this.apolloProvider = apolloProvider
  }

  async initClient(locale) {
    this.locale = locale

    this.client =
      this.locale === 'en-ca'
        ? this.apolloProvider.clients.shopifyCA
        : this.apolloProvider.clients.shopifyUS
  }

  client() {
    return this.client
  }

  async initCheckout() {
    let checkoutId = Cookie.get(`gee_checkout_new_${this.locale}`)

    if (!checkoutId) return this.createCheckout()

    const { cartData } = await this.client.query({
      query: GET_CART_CHECKOUT_URL,
      variables: { cartId: checkoutId }
    })

    if(cartData?.cart?.checkoutUrl) return this.createCheckout()

    const { data } = await this.client.query({
      query: GET_CART_ID_AND_CHECKOUT_URL,
      variables: { cartId: checkoutId },
    })

    return data?.cart
  }

  async createCheckout() {
    Cookie.remove(`gee_checkout_${this.locale}`) // OLD COOKIE
    Cookie.remove(`gee_checkout_new_${this.locale}`)

    const { data } = await this.client.mutate({
      mutation: CART_CREATE,
      variables: { input: {} },
    })
    const checkout  = data.cartCreate.cart

    if (process.client) {
      localStorage.removeItem(`gee_cart_${this.locale}`)
    }

    Cookie.set(`gee_checkout_new_${this.locale}`, checkout.id)

    return checkout
  }

  async updateCheckout(newLineItems) {
    var lines= [];
    newLineItems.forEach((line) => {
      lines.push({ merchandiseId: line.variantId, quantity: line.quantity });
    })

    let checkoutId = Cookie.get(`gee_checkout_new_${this.locale}`)

    try {

      if (!checkoutId) {
        const newCheckout = this.createCheckout()

        return this.checkoutLineItemReplace({
          lines: lines,
          cartId: newCheckout.id,
        })
      }

      await this.clearCart(checkoutId, lines);

      //newLineItems[0].sellingPlanId = "Z2lkOi8vc2hvcGlmeS9TZWxsaW5nUGxhbi8xMDU3NDU2MTg2";

      return this.checkoutLineItemReplace({
        lines: lines,
        cartId: checkoutId,
      })
    } catch (error) {
      console.log(error)
      alert('Sorry something went wrong')
    }
  }

  async checkoutLineItemReplace(variables) {

    const { data } = await this.client.mutate({
      mutation: CART_LINES_ADD,
      variables: variables,
    })
    console.log(data);

    return data;
  }

  async applyDiscount(discountCode, checkoutId) {
    try {
      const { data } = await this.client.mutate({
        mutation: CART_DISCOUNT_CODES_UPDATE,
        variables: {
          cartId: checkoutId,
          discountCodes: [discountCode]
        }
      });

      if (data?.cartDiscountCodesUpdate?.userErrors.length > 0) {
        console.error("Error applying discount:", data.cartDiscountCodesUpdate.userErrors);
      } else {
        console.log("Discount applied:", data.cartDiscountCodesUpdate.cart.discountCodes);
      }
    } catch (error) {
      console.error("Error applying discount code:", error);
    }


    /*
    try {
      const { data } = await this.client.mutate({
        mutation: applyDiscountCode,
        variables: { discountCode, checkoutId },
      })

      console.log(discountCode)

      const { checkoutDiscountCodeApplyV2 } = data

      console.log(checkoutDiscountCodeApplyV2)

      if (checkoutDiscountCodeApplyV2.checkoutUserErrors.length) {
        console.log(checkoutDiscountCodeApplyV2.checkoutUserErrors)
        throw new Error(checkoutDiscountCodeApplyV2.checkoutUserErrors)
      }

      return checkoutDiscountCodeApplyV2.checkout
    } catch (error) {
      console.log(error)
      throw new Error(
        ' Something went wrong adding your discount to the checkout.'
      )
    }
    */
  }

  async getDiscountProductIds() {
    const products = await this.fetchAllDiscountProducts()

    return products.map(item => item.node.id)
  }

  async fetchAllDiscountProducts() {
    let hasNextPage = true
    let lastCursor = null
    let products = []

    do {
      const { data } = await this.client.query({
        query: findCollectionProductsByHandle,
        variables: {
          handle: 'all-w-exclusions-for-blackfriday-sale',
          cursor: lastCursor,
        },
      })

      const { edges, pageInfo } = data.collectionByHandle.products
      hasNextPage = pageInfo.hasNextPage
      lastCursor = [...edges].pop().cursor
      products = [...products, ...edges]
    } while (hasNextPage)
    return products
  }


  async clearCart(cartId) {

    try {
      const { data: cartData } = await this.client.query({
        query: GET_CART_LINES,
        variables: { cartId },
        fetchPolicy: "network-only"
      });

      const cartLines = cartData?.cart?.lines?.edges.map(edge => edge.node.id) || [];

      if (cartLines.length === 0) {
        console.log("Cart is already empty.");
        return;
      }

      const { data } = await this.client.mutate({
        mutation: CART_LINES_REMOVE,
        variables: { cartId, lineIds: cartLines },
      });

      console.log("Cart has been cleared:", data.cartLinesRemove.cart);
    } catch (error) {
      console.error("Error clearing cart:", error);
    }
  }

}




